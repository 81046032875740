<template>
    <div class="row justify-center">
        <div
            :class="`row bg-${$q.dark.isActive ? 'dark' : 'white'}`"
            style="width: 100%; max-width: 1200px;"
        >
            <div class="col full-height">
                <div
                    class="row q-layout-padding"
                    :style="`height: 90%`"
                >
                    <q-termo-adesao :termoCliente="false" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import QTermoAdesao from '@/components/shared/QTermoAdesao'

export default {
    name: 'QContrato',

    components: {
        QTermoAdesao
    }
}
</script>

<style>

</style>
